export const magnoEntityValues = [{
  value: 'MAGNO_IT_RECRUITMENT',
  label: 'Magno IT Recruitment'
}, {
  value: 'MAGNO_IT_PROJECTS',
  label: 'Magno IT Projects'
}, {
  value: 'MAGNO_IT_BELGIUM',
  label: 'Magno IT Belgium'
}, {
  value: 'MAGNO_IT_POLSKA',
  label: 'Magno IT Polska'
}]

export const clientValues = [{
  value: 'ABN_AMRO',
  label: 'Abn Amro'
}, {
  value: 'ELSEVIER',
  label: 'Elsevier'
}, {
  value: 'INFOSYS',
  label: 'Infosys'
}, {
  value: 'ING',
  label: 'ING'
}, {
  value: 'KLAVERBLAD',
  label: 'Klaverblad'
}, {
  value: 'LIBERTY_GLOBAL',
  label: 'Liberty Global'
}, {
  value: 'RABOBANK',
  label: 'Rabobank'
}, {
  value: 'TELENET',
  label: 'Telenet'
}]

export const contractTypeValues = [{
  value: 'EMPLOYED_BY_EXTERNAL_SUPPLIER',
  label: 'Employed by external supplier'
}, {
  value: 'MIR_EMPLOYEE',
  label: 'MIR employee'
}, {
  value: 'MIB_EMPLOYEE',
  label: 'MIB employee'
}, {
  value: 'ONE_MAN_COMPANY',
  label: 'One man company'
}]

export const workPermitValues = [{
  value: 'HAS_PERMIT',
  label: 'Has permit'
}, {
  value: 'NO_PERMIT',
  label: 'No permit'
}, {
  value: 'NEEDS_PERMIT',
  label: 'Needs permit'
}]

export const ruling30Values = [{
  value: 'YES',
  label: 'Yes'
}, {
  value: 'NO',
  label: 'No'
}]
