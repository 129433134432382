import { format } from 'date-fns'
import React, { useState } from 'react'
import { useLocation } from 'wouter'

import { authedPost, authedPut } from '../lib/http'
import { Client, ContractType, MagnoEntity, Placement } from '../types/types'

import './PlacementForm.css'

import {
  magnoEntityValues,
  clientValues,
  contractTypeValues,
  workPermitValues,
  ruling30Values
 } from './values'

interface InputProps {
  label: string
  id?: string
  errors?: any
}

const Input: React.FC<InputProps> = ({ label, id, errors, children }) => {
  return <div id={id}>
    {id && errors && errors[id] &&
      <div className='error-sml'>{errors[id]}</div>}
    <label className='label'>{label}</label>
    <div className='value'>
      {children}
    </div>
  </div>
}

const EmptySelectOption = () => {
  return <option value=''>Select option</option>
}

const defaultForm = {
  MagnoEntity: 'MAGNO_IT_RECRUITMENT',
  Client: 'ABN_AMRO',
  AccountManager: '',
  Recruiter: '',
  RoleAdaptID: '',
  RoleName: '',
  CandidateAdaptID: '',
  CandidateName: '',
  CandidateEmail: '',
  CandidatePhone: '',
  CandidateContractType: 'EMPLOYED_BY_EXTERNAL_SUPPLIER',
  CandidatePaymentRequirements: '',
  ContactName: '',
  ContactEmail: '',
  ContactPhone: '',
  WorkPermit: undefined,
  Ruling30: undefined,
  ProjectStartDate: format(new Date(), 'yyyy-MM-dd'),
  ProjectEndDate: format(new Date(), 'yyyy-MM-dd'),
  ProjectHoursPerWeek: '',
  GrossWagePerHourAmount: '',
  GrossWagePerHourCurrency: 'EUR',
  CostPricePerHourAmount: '',
  CostPricePerHourCurrency: 'EUR',
  Remarks: ''
}

const validEmail = (input: string) => {
  if (!input) {
    return false
  }
  console.log(input.indexOf('@'))
  if (input.indexOf('@') === -1 || input.indexOf('.') === -1) {
    return false
  }
  return true
}

const validate = (form: Placement) => {
  const errors = {} as any
  if (!form.RoleAdaptID) {
    errors.RoleAdaptID = 'Please enter the Adapt ID of the role.'
  }
  if (!form.RoleName) {
    errors.RoleName = 'Please enter the role.'
  }
  if (!form.CandidateAdaptID) {
    errors.CandidateAdaptID = 'Please enter the Adapt ID of the candidate.'
  }
  if (!form.CandidateName) {
    errors.CandidateName = 'Please enter the candidate\'s name.'
  }
  if (!form.CandidateEmail) {
    errors.CandidateEmail = 'Please enter the candidate\'s email.'
  } else if (!validEmail(form.CandidateEmail)) {
    errors.CandidateEmail = 'Please enter a valid email.'
  }
  if (!form.CandidatePhone) {
    errors.CandidatePhone = 'Please enter the candidate\'s phone number.'
  }
  if (form.CandidateContractType === 'EMPLOYED_BY_EXTERNAL_SUPPLIER') {
    if (!form.ContactName) {
      errors.ContactName = 'Please enter the contact\'s name.'
    }
    if (!form.ContactEmail) {
      errors.ContactEmail = 'Please enter the contact\'s email.'
    } else if (!validEmail(form.ContactEmail)) {
      errors.ContactEmail = 'Please enter a valid email.'
    }
    if (!form.ContactPhone) {
      errors.ContactPhone = 'Please enter the contact\'s phone.'
    }
  }
  if (!form.ProjectStartDate) {
    errors.ProjectStartDate = 'Please enter the project start date.'
  }
  if (!form.ProjectEndDate) {
    errors.ProjectEndDate = 'Please enter the project end date.'
  }
  if (!form.ProjectHoursPerWeek) {
    errors.ProjectHoursPerWeek = 'Please enter the hours per week.'
  }
  if (!form.GrossWagePerHourAmount) {
    errors.GrossWagePerHourAmount = 'Please enter the gross wage per hour.'
  }
  if (!form.CostPricePerHourAmount) {
    errors.CostPricePerHourAmount = 'Please enter the cost price per hour.'
  }
  return errors
}

const hasKeys = (object: object) => {
  return Object.keys(object).length > 0
}

interface Props {
  placement?: Placement
}

export const PlacementForm = ({ placement }: Props) => {
  const [form, setForm] = useState(placement || defaultForm as Placement)
  const [errors, setErrors] = useState({})
  const [, setLocation] = useLocation()

  const savePlacement = async () => {
    let result
    if (form.ID) {
      result = await authedPut(`placements/${form.ID}`, form)
    } else {
      result = await authedPost('placements', form)
    }
    await result.json() as Placement
    setLocation('/')
  }

  const setFormValueOrUndefined = (name: string, value: string) => {
    setForm({
      ...form,
      [name]: value === '' ? undefined : value
    })
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    const errors = validate(form)
    if (hasKeys(errors)) {
      setErrors(errors)
      window.scrollTo(0, 0)
      return
    }
    setErrors({})
    savePlacement()
  }

  return <div className='contract-form placement-form'>
      <div className='contract-form-title'>
        New Placement
      </div>
      <form>
        {hasKeys(errors) &&
          <div className='error-sml'>Your form has errors. Please fix them and resubmit.</div>}
        <div className='form-sub-title'>
          Contract parties
        </div>
        <div className='form-inline'>
          <Input label='Magno entity' id='MagnoEntity'>
            <select onChange={e => setForm({...form, MagnoEntity: e.target.value as MagnoEntity})} value={form.MagnoEntity}>
              {magnoEntityValues.map(v => <option key={v.value} value={v.value}>{v.label}</option>)}
            </select>
          </Input>
          <Input label='Client' id='Client'>
            <select onChange={e => setForm({...form, Client: e.target.value as Client})} value={form.Client}>
              {clientValues.map(v => <option key={v.value} value={v.value}>{v.label}</option>)}
            </select>
          </Input>
        </div>
        <div className='form-inline'>
          <Input label='Account manager' id='AccountManager'>
            <input onChange={e => setForm({...form, AccountManager: e.target.value})} value={form.AccountManager} />
          </Input>
          <Input label='Recruiter' id='Recruiter'>
            <input onChange={e => setForm({...form, Recruiter: e.target.value})} value={form.Recruiter} />
          </Input>
        </div>

        <div className='form-sub-title'>
          Role
        </div>
        <div className='form-inline'>
          <Input label='Role Adapt ID' id='RoleAdaptID' errors={errors}>
            <input onChange={e => setForm({...form, RoleAdaptID: e.target.value})} value={form.RoleAdaptID} />
          </Input>
          <Input label='Role' id='RoleName' errors={errors}>
            <input onChange={e => setForm({...form, RoleName: e.target.value})} value={form.RoleName} />
          </Input>
        </div>

        <div className='form-sub-title'>
          Candidate
        </div>
        <div className='form-inline'>
          <Input label='Candidate Adapt ID' id='CandidateAdaptID' errors={errors}>
            <input onChange={e => setForm({...form, CandidateAdaptID: e.target.value})} value={form.CandidateAdaptID} />
          </Input>
          <Input label='Name' id='CandidateName' errors={errors}>
            <input onChange={e => setForm({...form, CandidateName: e.target.value})} value={form.CandidateName} />
          </Input>
        </div>
        <div className='form-inline'>
          <Input label='Email' id='CandidateEmail' errors={errors}>
            <input onChange={e => setForm({...form, CandidateEmail: e.target.value})} value={form.CandidateEmail} />
          </Input>
          <Input label='Phone number' id='CandidatePhone' errors={errors}>
            <input onChange={e => setForm({...form, CandidatePhone: e.target.value})} value={form.CandidatePhone} />
          </Input>
        </div>
        <div className='form-inline'>
          <Input label='Candidate contract type' id='CandidateContractType'>
            <select onChange={e => setForm({...form, CandidateContractType: e.target.value as ContractType})} value={form.CandidateContractType}>
              {contractTypeValues.map(v => <option key={v.value} value={v.value}>{v.label}</option>)}
            </select>
          </Input>
        </div>
        {form.CandidateContractType === 'EMPLOYED_BY_EXTERNAL_SUPPLIER' &&
        <div className='form-inline'>
          <Input label='Contact name' id='ContactName' errors={errors}>
            <input onChange={e => setForm({...form, ContactName: e.target.value})} value={form.ContactName} />
          </Input>
          <Input label='Contact email' id='ContactEmail' errors={errors}>
            <input onChange={e => setForm({...form, ContactEmail: e.target.value})} value={form.ContactEmail} />
          </Input>
          <Input label='Contact phone' id='ContactPhone' errors={errors}>
            <input onChange={e => setForm({...form, ContactPhone: e.target.value})} value={form.ContactPhone} />
          </Input>
        </div>}

        {form.CandidateContractType === 'MIR_EMPLOYEE' &&
          <div className='form-inline'>
            <Input label='Work permit' id='WorkPermit'>
              <select onChange={e => setFormValueOrUndefined('WorkPermit', e.target.value)} value={form.WorkPermit}>
                <EmptySelectOption />
                {workPermitValues.map(v => <option key={v.value} value={v.value}>{v.label}</option>)}
              </select>
            </Input>
            <Input label='30% ruling required' id='Ruling30'>
              <select onChange={e => setFormValueOrUndefined('Ruling30', e.target.value)} value={form.Ruling30}>
                <EmptySelectOption />
                {ruling30Values.map(v => <option key={v.value} value={v.value}>{v.label}</option>)}
              </select>
            </Input>
          </div>}

        <div className='form-inline'>
          <Input label='Candidate payment requirements' id='CandidatePaymentRequirements'>
            <textarea cols={70} rows={3}
              onChange={e => setForm({...form, CandidatePaymentRequirements: e.target.value})}
              value={form.CandidatePaymentRequirements} />
          </Input>
        </div>

        <div className='form-sub-title'>
          Project details
        </div>
        <div className='form-inline'>
          <Input label='Start date' id='ProjectStartDate' errors={errors}>
            <input type='date' onChange={e => setForm({...form, ProjectStartDate: e.target.value})} value={form.ProjectStartDate} />
          </Input>
          <Input label='End date' id='ProjectEndDate' errors={errors}>
            <input type='date' onChange={e => setForm({...form, ProjectEndDate: e.target.value})} value={form.ProjectEndDate} />
          </Input>
          <Input label='Hours per week' id='ProjectHoursPerWeek' errors={errors}>
            <input onChange={e => setForm({...form, ProjectHoursPerWeek: e.target.value})} value={form.ProjectHoursPerWeek} />
          </Input>
        </div>

        <div className='form-sub-title'>
          Rates
        </div>
        <div className='form-inline'>
          <Input label='Gross wage per hour' id='GrossWagePerHourAmount' errors={errors}>
            <input onChange={e => setForm({...form, GrossWagePerHourAmount: e.target.value})} value={form.GrossWagePerHourAmount} />
            <select onChange={e => setForm({...form, GrossWagePerHourCurrency: e.target.value})} value={form.GrossWagePerHourCurrency}>
              <option value='EUR'>EUR</option>
              <option value='Zloty'>Zloty</option>
              <option value='GBP'>GBP</option>
            </select>
          </Input>
          <Input label='Cost price per hour' id='CostPricePerHourAmount' errors={errors}>
            <input onChange={e => setForm({...form, CostPricePerHourAmount: e.target.value})} value={form.CostPricePerHourAmount} />
            <select onChange={e => setForm({...form, CostPricePerHourCurrency: e.target.value})} value={form.CostPricePerHourCurrency}>
              <option value='EUR'>EUR</option>
              <option value='Zloty'>Zloty</option>
              <option value='GBP'>GBP</option>
            </select>
          </Input>
        </div>

        <div className='form-sub-title'>
          Remarks
        </div>
        <div className='form-inline'>
          <Input label='Remarks' id='Remarks'>
            <textarea cols={70} rows={5}
              onChange={e => setForm({...form, Remarks: e.target.value})}
              value={form.Remarks} />
          </Input>
        </div>

        <div>
          <button onClick={(e) => onSubmit(e)}>Submit</button>
        </div>
      </form>
  </div>
}
