import React, { useEffect, useState } from 'react'
import { Params, useLocation } from 'wouter'
import { authedGet, authedPost } from '../lib/http'
import { Placement, PlacementAction } from '../types/types'
import { PlacementFields } from './PlacementFields'
import { PlacementForm } from './PlacementForm'
import { RejectModal } from './RejectModal'

import './Placement.css'
import { format, parseJSON } from "date-fns"

interface Props {
  params: Params<{ id: string }>
}

export const ViewPlacement = (props: Props) => {
  const [placement, setPlacement] = useState(undefined as Placement | undefined)
  const [showAddApproverModal, setShowAddApproverModal] = useState(false)
  const [, setLocation] = useLocation()

  const loadPlacement = async (id: string) => {
    const result = await authedGet(`placements/${id}`)
    const placement = await result.json() as Placement
    setPlacement(placement)
  }

  useEffect(() => {
    loadPlacement(props.params.id)
  }, [props.params.id])

  const onApprove = async () => {
    if (window.confirm(`Are you sure you want to approve this placement?`)) {
      await authedPost(`placements/${props.params.id}/action`, {
        Action: 'APPROVE',
        Remarks: ''
      })
      setLocation('/')
    }
  }

  const onReject = () => {
    setShowAddApproverModal(true)
    window.scrollTo(0, 0)
  }

  const onSubmitReject = async (reason: string) => {
    const result = await authedPost(`placements/${props.params.id}/action`, {
      Action: 'REJECT',
      Remarks: reason
    })
    const placement = await result.json() as Placement
    setShowAddApproverModal(false)
    setPlacement(placement)
  }

  const canEdit = placement?.State === 'DRAFT' // TODO
  const canApproveOrReject = placement?.State === 'SUBMITTED'

  return <div className='contract-form'>
    <div className='contract-form-title'>
      {placement && `Placement ${placement.CandidateName} (${placement.Client}) - ${placement.State}`}
    </div>
    {placement &&
      <React.Fragment>
        {canEdit && <PlacementForm placement={placement} />}
        {!canEdit && <PlacementFields placement={placement} />}
        {showAddApproverModal &&
          <RejectModal
            submitReject={onSubmitReject}
            setShowAddApproverModal={setShowAddApproverModal}
          />}
        {canApproveOrReject && <div>
          <button onClick={onApprove}>Approve</button>
          <button onClick={onReject}>Reject</button>
        </div>}
        {placement.PlacementActions.map(action => <Action key={action.ID} {...action} />)}

      </React.Fragment>
    }
  </div>
}

const Action = (action : PlacementAction) => {
  return <div className='action'>
  <div>{action.Action} by {action.CreatedByName}</div>
  <div className='date'>{format(parseJSON(action.CreatedAt), 'dd-MM-yyyy HH:mm')}</div>
  <div className='reason'>{action.Remarks}</div>
</div>
}
