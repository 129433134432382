import React, { useEffect, useState } from 'react'
import { Link } from 'wouter'

import './ViewPlacements.css'

import { authedGet } from '../lib/http'
import { Placement } from '../types/types'

export const ViewPlacements = () => {
  const [placements, setPlacements] = useState([] as Placement[])

  const fetchPlacements = async () => {
    const result = await authedGet('placements')
    if (result.status === 200) {
      const json = await result.json() as Placement[]
      setPlacements(json)
    }
  }
  useEffect(() => {
    fetchPlacements()
  }, [])

  if (placements.length === 0) {
    return <div/>
  }

  return <table cellSpacing='0'>
  <thead>
    <tr>
      <th>
        State
      </th>
      <th>
        Candidate
      </th>
      <th>
        Account manager
      </th>
      <th>
        Recruiter
      </th>
      <th>
        Start date
      </th>
    </tr>
  </thead>
  <tbody>
    {placements.map(placement =>
      <Link key={placement.ID} href={`/placements/${placement.ID}`}>
    <tr className='row'>
      <td>{placement.State}</td>
      <td>{placement.CandidateName}</td>
      <td>{placement.AccountManager}</td>
      <td>{placement.Recruiter}</td>
      <td>{placement.ProjectStartDate}</td>
    </tr></Link>)}
  </tbody>
</table>
}