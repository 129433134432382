import React, { useState } from 'react'

import { Modal } from '../lib/ui/Modal'

interface Props {
  setShowAddApproverModal: (shown: boolean) => void
  submitReject: (reason: string) => void
}

export const RejectModal = ({ setShowAddApproverModal, submitReject }: Props) => {
  const [reason, setReason] = useState('')
  const [error] = useState(undefined)

  const onSubmit = () => {
    submitReject(reason)
  }

  return <Modal>
    <div className='white bg-red head'>Reject placement</div>
    {error && <div style={{margin: 10}} className='error'>⚠️ {error}</div>}
    <div className='modal-section'>
      <button
        onClick={onSubmit}>Submit</button>
      <button onClick={e => setShowAddApproverModal(false)}>Cancel</button>
    </div>
    <div className='modal-section'>
      <div className='input-block'>
        <div className='label'>
          Reason:
        </div>
        <div className='value'>
          <textarea style={{width: '98%'}} rows={5} onChange={e => setReason(e.target.value)} value={reason}></textarea>
        </div>
      </div>
    </div>
  </Modal>
}
