import React, { useEffect, useState } from 'react'
import { Link, Route } from 'wouter'
import './App.css'
import './lib/ui/ui.css'
import './lib/ui/Form.css'

import { authedGet } from './lib/http'
import { JwtUser } from './types/types'
import { ViewPlacement } from './components/ViewPlacement'
import { NewPlacement } from './components/NewPlacement'
import { ViewPlacements } from './components/ViewPlacements'

function App() {
  const [user, setUser] = useState(undefined as JwtUser | undefined)
  const [error, setError] = useState(undefined as string | undefined)

  const fetchUser = async () => {
    const result = await authedGet('user')
    const json = await result.json()
    if (result.status !== 200) {
      return setError(json.error)
    }
    setUser(json as JwtUser)
  }

  useEffect(() => {
    fetchUser()
  }, [])

  if (error) {
    const description = error === 'invalid token' && 'You are not currently authenticated. Please try logging into the Magno Intranet again. If you still have problems let back office know.'
    return <div>
      <div>Error loading application: "{error.toUpperCase()}"</div>
      <div>{description}</div>
    </div>
  }

  if (!user) {
    return <div />
  }

  return (
    <div className='container container-white'>
      <h1>Hello, {user?.Email}</h1>
      <Link href='/'>
        <button>View placements</button>
      </Link>
      <Link href='/new-placement'>
        <button>New placement</button>
      </Link>

      <Route path='/' component={ViewPlacements} />
      <Route path='/new-placement' component={NewPlacement} />
      <Route path='/placements/:id' component={ViewPlacement} />

    </div>
  )
}

export default App
