
export const getApiUrl = () => {
  const isLocal = window.location.host.indexOf('localhost') !== -1
  return isLocal ? 'http://localhost:8000/api' : '/api'
}

const config = {
  baseUrl: getApiUrl()
}

const getSessionId = (): string => {
  const cookies = document.cookie.split(';')
  const magnoJwt = cookies.find(cookie => cookie.trim().indexOf('MagnoToken') === 0)
  if (magnoJwt) {
    const jwt = magnoJwt.split('=', 2)[1]
    return jwt
  }
  return localStorage.getItem('sessionId') || ''
}

export const authedGet = async (path: string) => {
  const result = await fetch(`${config.baseUrl}/${path}`, {
    method: 'GET',
    credentials: "same-origin",
    headers: {
      'X-authenticated': getSessionId(),
      'Cookie': document.cookie
    }
  })
  return result
}

const authedMutate = async (method: string, path: string, body: object) => {
  const result = await fetch(`${config.baseUrl}/${path}`, {
    method,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-authenticated': getSessionId()
    },
    body: JSON.stringify(body)
  })
  return result
}

export const authedDelete = async (path: string, body: object) => {
  return await authedMutate('DELETE', path, body)
}

export const authedPut = async (path: string, body: object) => {
  return await authedMutate('PUT', path, body)
}

export const authedPost = async (path: string, body: object) => {
  return await authedMutate('POST', path, body)
}
