import React from 'react'
import { Placement } from '../types/types'

import './PlacementFields.css'

interface Props {
  placement: Placement
}

interface LabelValueProps {
  label: string
  value: string | undefined
}

const LabelValue = ({ label, value }: LabelValueProps) => {
  return <div>
    <label className='label'>{label}</label>
    <div className='value'>{value || ''}</div>
  </div>
}

export const PlacementFields = ({ placement }: Props) => {
  return <div className='fields'>
    <div className='form-sub-title'>
      Contract parties
    </div>
    <div className='form-inline'>
      <LabelValue label='Magno entity' value={placement.MagnoEntity} />
      <LabelValue label='Client' value={placement.Client} />
    </div>
    <div className='form-inline'>
      <LabelValue label='Account manager' value={placement.AccountManager} />
      <LabelValue label='Recruiter' value={placement.Recruiter} />
    </div>
    <div className='form-sub-title'>
      Role
    </div>
    <div className='form-inline'>
      <LabelValue label='Role Adapt ID' value={placement.RoleAdaptID} />
      <LabelValue label='Role' value={placement.RoleName} />
    </div>
    <div className='form-sub-title'>
      Candidate
    </div>
    <div className='form-inline'>
      <LabelValue label='Candidate Adapt ID' value={placement.CandidateAdaptID} />
      <LabelValue label='Name' value={placement.CandidateName} />
    </div>
    <div className='form-inline'>
      <LabelValue label='Email' value={placement.CandidateEmail} />
      <LabelValue label='Phone number' value={placement.CandidatePhone} />
    </div>
    <div className='form-inline'>
      <LabelValue label='Candidate contract type' value={placement.CandidateContractType} />
    </div>
    {placement.CandidateContractType === 'EMPLOYED_BY_EXTERNAL_SUPPLIER' &&
    <div className='form-inline'>
      <LabelValue label='Contact name' value={placement.ContactName} />
      <LabelValue label='Contact email' value={placement.ContactEmail} />
      <LabelValue label='Contact phone' value={placement.ContactPhone} />
    </div>}
    {placement.CandidateContractType === 'MIR_EMPLOYEE' &&
      <div className='form-inline'>
        <LabelValue label='Work permit' value={placement.WorkPermit} />
        <LabelValue label='30% ruling required' value={placement.Ruling30} />
      </div>}
    <div className='form-inline'>
      <LabelValue label='Candidate payment requirements' value={placement.CandidatePaymentRequirements} />
    </div>
    <div className='form-sub-title'>
      Project details
    </div>
    <div className='form-inline'>
      <LabelValue label='Start date' value={placement.ProjectStartDate} />
      <LabelValue label='End date' value={placement.ProjectEndDate} />
      <LabelValue label='Hours per week' value={placement.ProjectHoursPerWeek} />
    </div>
    <div className='form-sub-title'>
      Rates
    </div>
    <div className='form-inline'>
      <LabelValue label='Gross wage per hour' value={`${placement.GrossWagePerHourCurrency} ${placement.GrossWagePerHourAmount}`} />
      <LabelValue label='Cost price per hour' value={`${placement.CostPricePerHourCurrency} ${placement.CostPricePerHourAmount}`} />
    </div>
    <div className='form-sub-title'>
      Remarks
    </div>
    <div className='form-inline'>
      {placement.Remarks}
    </div>
  </div>
}
